import { MaterielModel, PediatryEntourageModel, PediatryMaterielModel, PediatryPADModel, PediatrySituationModel } from '@/models/podiatry-observation-model'
import { BehaviorSubject } from 'rxjs'
import http from '@/http-client'
import Commons from '@/components/shared/Helpers/commons'

export class PediatryService {
  private static instance: PediatryService
  private _pediatrySituationSelectedSource = new BehaviorSubject<PediatrySituationModel>(
    { date: Commons.GetTodayFormatted() }
  )

  private _pediatryEntourageSelectedSource = new BehaviorSubject<PediatryEntourageModel>(
    { date: Commons.GetTodayFormatted() }
  )

  public pediatrySituationSelected$ = this._pediatrySituationSelectedSource.asObservable()
  public pediatryEntourageSelected$ = this._pediatryEntourageSelectedSource.asObservable()
  public activeIndex = 0

  public static getInstance (): PediatryService {
    if (!PediatryService.instance) {
      PediatryService.instance = new PediatryService()
    }
    return PediatryService.instance
  }

  public setTabIndex (idx: number) {
    this.activeIndex = idx
  }

  public getTabIndex () {
    return this.activeIndex
  }

  public updatePediatrySituationSelected (situation: PediatrySituationModel) {
    this._pediatrySituationSelectedSource.next(situation)
  }

  public updatePediatryEntourageSelected (entourage: PediatryEntourageModel) {
    this._pediatryEntourageSelectedSource.next(entourage)
  }

  // Evaluation situation
  public async AddPediatrySituation (dossierId: string, situationPediatry: PediatrySituationModel) {
    situationPediatry.dossierId = dossierId
    return await http.post(`/dossierPatient/pediatry/situation/${dossierId}`, situationPediatry)
  }

  public async GetDossierPediatrySituations (dossierId: string) {
    const response = await http.get(`/dossierPatient/pediatry/situations/${dossierId}`)
    return (response.data) as PediatrySituationModel[]
  }

  public async deletePediatrySituation (id: string | undefined) {
    if (!id) return
    return await http.delete(`/dossierPatient/pediatry/situation/${id}`)
  }

  // Entourage
  public async AddPediatryEntourage (dossierId: string, entourage: PediatryEntourageModel) {
    entourage.dossierId = dossierId
    return await http.post(`/dossierPatient/pediatry/entourage/${dossierId}`, entourage)
  }

  public async GetDossierPediatryEntourages (dossierId: string) {
    const response = await http.get(`/dossierPatient/pediatry/entourages/${dossierId}`)
    return (response.data) as PediatryEntourageModel[]
  }

  public async deletePediatryEntourage (id: string | undefined) {
    if (!id) return
    return await http.delete(`/dossierPatient/pediatry/entourage/${id}`)
  }

  // PAD
  public async UpsetPediatryPAD (dossierId: string, pad: PediatryPADModel) {
    pad.dossierId = dossierId
    return await http.post(`/dossierPatient/pediatry/pad/${dossierId}`, pad)
  }

  public async GetDossierPADs (dossierId: string) {
    const response = await http.get(`/dossierPatient/pediatry/pads/${dossierId}`)
    return (response.data) as PediatryPADModel[]
  }

  public async deletePediatryPAD (id: string | undefined) {
    if (!id) return
    return await http.delete(`/dossierPatient/pediatry/pad/${id}`)
  }

  // ==> materiels
  public async upsetPediatryMateriels (dossierId: string, pediatryMateriel: PediatryMaterielModel) {
    pediatryMateriel.dossierId = dossierId
    return await http.post(`/dossierPatient/materiel/${dossierId}`, pediatryMateriel)
  }

  public async upsetMateriel (dossierId: string, pediatryMateriel: PediatryMaterielModel, materialId: number) {
    pediatryMateriel.dossierId = dossierId
    pediatryMateriel.materialId = materialId
    return await http.post(`/dossierPatient/materiel/${dossierId}`, pediatryMateriel)
  }

  public async getPatientMateriel (dossierId: string, type: number) {
    const response = await http.get(`/dossierPatient/patientmateriel/${dossierId}/${type}`)
    return (response.data) as PediatryMaterielModel[]
  }

  public async getMateriels (type: number, date: string, prescriptionId?: string, selectedMaterialId?: number) {
    let query = `/dossierPatient/materiels/${type}`
    const params = [{ name: 'prestationId', value: prescriptionId ?? '' }, { name: 'selectedMaterialId', value: `${selectedMaterialId ?? ''}` }, { name: 'date', value: date }].filter(s => !!s.value && !!s.value.trim())
    for (let i = 0; i < params.length; ++i) {
      if (i === 0) {
        query += '?'
      } else {
        query += '&'
      }
      query += `${params[i].name}=${params[i].value}`
    }

    const response = await http.get(query)
    return (response.data) as MaterielModel[]
  }

  public async deletePateintPediatryMateriel (id: string | undefined) {
    if (!id) return
    return await http.delete(`/dossierPatient/materiel/${id}`)
  }
}
