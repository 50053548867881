import { ValidationObserver } from 'vee-validate'
import { Component, Prop } from 'vue-property-decorator'
import Commons from '../../Helpers/commons'
import { MaterielPediatriqueLibre } from '@/views/Patients/Dossier/Constants'
import Alert from '@/components/shared/Alert.vue'
import MaterielDialogCommonForm from './MaterielDialogCommonForm.vue'
import MaterielHelper from '@/views/Patients/Materiel/MaterielHelper'
import MaterielDialogCommon from './MaterielDialogCommon'
import { PediatryMaterielModel } from '@/models/podiatry-observation-model'

@Component({
  components: {
    Alert,
    MaterielDialogCommonForm,
    ValidationObserver
  }
})
export default class MaterielLibreDialog extends MaterielDialogCommon {
  public ready = true

  public mounted () {
    this.refreshMaterial()
    this.selectedMateriel = this.selectedItem.materiel!

    this.$on("resetMaterielSelection", e => this.resetMaterielSelection())
    this.$on("close", e => this.resetState(true))
  }

  private refreshMaterial (refreshExistingMaterial = false) {
    this.ready = false
    let date = new Date().toISOString().substr(0, 10)
    if (this.formState.dateRange?.from) {
      date = this.formState.dateRange?.from
    }
    this.pediatryService.getMateriels(this.type, date, undefined, this.selectedItem?.materialId).then(items => {
      this.materials = items
      this.refreshExistingMaterial(refreshExistingMaterial)
    }).finally(() => {
      this.ready = true
    })
  }

  public onShowDialog (v: boolean) {
    if (v) {
      this.refreshMaterial()
      this.resetState(false)
    }
  }

  public buildModel (): PediatryMaterielModel {
    return {
      createdBy: this.formState.createdBy,
      createdByUserId: this.formState.createdByUserId,
      dateRange: this.formState.dateRange,
      dossierId: this.formState.dossierId,
      id: this.formState.id,
      isOrderdOnline: this.formState.isOrderdOnline,
      materialId: this.formState.materiel?.id,
      quantity: this.formState.quantity,
      remark: this.formState.remark,
      materielCategory: this.category
    }
  }

  public resetState (resetMateriel: boolean) {
    this.selectedMateriel = this.selectedItem?.materiel ?? {}
    if (!this.selectedItem?.id) {
      this.selectedItem.dateRange = {
        from: Commons.FormatDateForInputField(new Date())
      }
    }
    if (resetMateriel) {
      this.selectedMateriel = {}
    }
    this.formState = MaterielHelper.DefaultMaterialFormState(this.selectedItem, this.selectedMateriel)
  }

  public resetMaterielSelection () {
    this.resetState(true)
    this.$emit('refresh')
  }

  public get isPediatryOnly () {
    return this.type === MaterielPediatriqueLibre
  }

  public onDateChanged () {
    this.refreshMaterial()
    this.refreshMaterial(true)
  }
}
