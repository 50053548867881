import { MaterielModel, PediatryMaterielModel } from '@/models/podiatry-observation-model'
import { ValidationObserver } from 'vee-validate'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { MaterielPediatriqueLibre, MaterielPediatriquePrescription } from '@/views/Patients/Dossier/Constants'
import Alert from '@/components/shared/Alert.vue'
import MaterielHelper from '@/views/Patients/Materiel/MaterielHelper'

@Component({
  components: {
    Alert,
    ValidationObserver
  }
})
export default class MaterielDialogCommonForm extends Vue {
  @Prop({ default: {} })
  public value!: PediatryMaterielModel

  @Prop()
  public type!: number

  @Prop({ type: Array, required: true, default: () => { return [] } })
  public materials!: MaterielModel[]

  @Prop()
  public refreshPrice!: boolean

  public get isPediatryOnly () {
    return this.type === MaterielPediatriqueLibre || this.type === MaterielPediatriquePrescription
  }

  public update () {
    this.$emit('input', this.value)
  }

  public updateDate () {
    this.$emit('date-changed', this.value.dateRange)
  }

  @Watch('refreshPrice')
  public updatePrice () {
    if (this.value.materiel) {
      this.value.materiel.displayedPrice = this.getPrice(this.value.materiel)
    }
  }

  public onMaterielChanged (e) {
    if (!e) {
      this.value.materiel = {}
      this.value.quantity = undefined
    }
    if (this.isPostageMateriel) {
      this.value.quantity = 1
    }
    if (this.value.materiel) {
      this.value.materiel.displayedPrice = this.getPrice(this.value.materiel)
    }
  }

  public get isPostageMateriel () {
    const o = this.value?.materiel?.description?.indexOf('de port')
    if (o !== undefined) {
      return o > -1
    }
    return false
  }

  public get getPriceLabel () {
    if (this.value?.materiel?.pricePatient == null) {
      return 'Prix'
    }
    return 'Prix assurance'
  }

  public getPrice (materiel?: MaterielModel) {
    return MaterielHelper.computePrice(materiel)
  }

  public get hasRemark () {
    return this.value?.materiel?.remark && this.value.materiel.remark.trim().length > 0
  }
}
