import Commons from '@/components/shared/Helpers/commons'
import { PediatryMaterielModel } from '@/models/podiatry-observation-model'
import { Component, Vue, Prop } from 'vue-property-decorator'
import EmptyTimeLine from '@/components/shared/EmptyTimeLine.vue'
import { MaterielCategoryEnum } from '../Dossier/Constants'
import MaterielHelper from './MaterielHelper'

@Component({
  components: {
    EmptyTimeLine
  }
})
export default class MaterielTimeline extends Vue {
  @Prop()
  public category!: MaterielCategoryEnum

  @Prop({ type: Array, required: true, default: () => { return [] } })
  public materials!: PediatryMaterielModel[]

  private materielCategoryPrescription = MaterielCategoryEnum.MaterielCategoryPrescription

  public getPrice (item: PediatryMaterielModel) {
    const quantity = item.quantity ?? 0
    const discountFromQuantity = item.materiel?.discountFromQuantity ?? 0
    const price = discountFromQuantity > 0 && quantity >= discountFromQuantity ? (item.materiel?.discountedPrice ?? 0) : (MaterielHelper.computePrice(item.materiel) ?? 0)
    const priceAsNumber = Number(price)
    return item.materiel?.isPriceScale === true ? "prix échelonné" : `CHF ${(!isNaN(priceAsNumber) ? priceAsNumber * quantity : 0).toFixed(2)}`
  }

  public getPricePatient (item: PediatryMaterielModel) {
    const quantity = item.quantity ?? 0
    const discountFromQuantity = item.materiel?.discountFromQuantity ?? 0
    const price = discountFromQuantity > 0 && quantity >= discountFromQuantity ? (item.materiel?.discountedPricePatient ?? 0) : (item.materiel?.pricePatient ?? 0)
    return item.materiel?.isPriceScale === true ? "prix échelonné" : `CHF ${(price * quantity).toFixed(2)}`
  }

  public isDateActive (dateEnd: string) {
    return Commons.isDateActive(dateEnd)
  }

  public editMateriel (item: PediatryMaterielModel) {
    this.$emit('editMateriel', item)
  }

  public displayDeleteConfirmDialog (item: PediatryMaterielModel) {
    this.$emit('deleteMaterial', item)
  }

  public getMateriels () {
    this.$emit('refreshMaterial')
  }

  public getDescription (item: PediatryMaterielModel) {
    if (item.materiel?.pricePatient) {
      return `${item.quantity} ${item.materiel?.description} : ${item.materiel?.modele} (${this.getPrice(item)} + ${this.getPricePatient(item)})`
    }
    return `${item.quantity} ${item.materiel?.description} : ${item.materiel?.modele} (${this.getPrice(item)})`
  }
}
