var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline"},[(_vm.materials.length > 0)?_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.materials),function(item,i){return _c('v-timeline-item',{key:("material-" + i),class:_vm.isDateActive(item.dateRange.to) ? 'inactive' : 'active',attrs:{"fill-dot":"","small":"","color":_vm.isDateActive(item.dateRange.to) ? 'secondary' : 'primary',"icon":_vm.isDateActive(item.dateRange.to) ? 'mdi-close' : 'mdi-needle'}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 5 : 3}},[_c('v-card-title',[(!item.prestationId && _vm.category === _vm.materielCategoryPrescription)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"v-alert__icon elevation-4 white",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-bell ")])]}}],null,true)},[_c('span',[_vm._v("Ce matériel sur prescription n'est pas lié à une prescription")])]):_vm._e(),_c('h3',{class:_vm.isDateActive(item.dateRange.to) ? 'secondary--text' : 'primary--text'},[_vm._v(" "+_vm._s(_vm.getDescription(item))+" ")]),_c('em',{staticClass:"small-info text--secondary"},[_vm._v(" Créé par "+_vm._s(item.createdBy)+" ")]),_c('div',{staticClass:"actions-timeline"},[(!item.isLinkedToInvoice)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editMateriel(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v("Modifier")])]):_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-edit",on:{"click":function($event){return _vm.editMateriel(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Visualiser")])]),(!item.isLinkedToInvoice)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-delete",on:{"click":function($event){return _vm.displayDeleteConfirmDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v("Supprimer")])]):_vm._e()],1)],1),_c('v-card-text',[_c('div',{staticClass:"textsLight--text card-descr-item"},[_c('strong',[_vm._v("Date: ")]),(item.dateRange.to)?_c('span',[_vm._v("du "+_vm._s(_vm._f("date-fr")(item.dateRange.from))+" au "+_vm._s(_vm._f("date-fr")(item.dateRange.to)))]):_c('span',[_vm._v("depuis le "+_vm._s(_vm._f("date-fr")(item.dateRange.from)))])]),(item.materiel.code)?_c('div',{staticClass:"textsLight--text card-descr-item"},[_c('strong',[_vm._v("Code LIMA: ")]),_vm._v(" "+_vm._s(item.materiel.code)+" ")]):_vm._e(),(item.remark)?_c('div',{staticClass:"textsLight--text card-descr-item"},[_c('strong',[_vm._v("Remarque: ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(item.remark))}})]):_vm._e()])],1)]}}],null,true)})],1)}),1):_c('empty-time-line',{on:{"icon-click":function($event){return _vm.getMateriels()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }