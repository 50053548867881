import { MaterielModel, PediatryMaterielModel } from '@/models/podiatry-observation-model'

export default class MaterielHelper {
  public static DefaultMaterialFormState (selectedItem: PediatryMaterielModel, selectedMateriel: MaterielModel): PediatryMaterielModel {
    selectedMateriel.displayedPrice = MaterielHelper.computePrice(selectedMateriel)
    return {
      createdBy: selectedItem?.createdBy,
      createdByUserId: selectedItem?.createdByUserId,
      dateRange: selectedItem?.dateRange,
      dossierId: selectedItem?.dossierId,
      id: selectedItem?.id,
      isOrderdOnline: selectedItem?.isOrderdOnline,
      materiel: selectedMateriel,
      materialId: selectedMateriel?.id,
      quantity: selectedItem?.quantity,
      remark: selectedItem?.remark,
      prestationId: selectedItem?.prestationId,
      isLinkedToInvoice: selectedItem?.isLinkedToInvoice
    }
  }

  public static computePrice (materiel?: MaterielModel): string {
    if (materiel?.isPriceScale === true) {
      return 'prix échelonné'
    }
    if (materiel?.discount) {
      return `${(Number(materiel.price!) + Number(materiel.discount.price!))}`
    }
    return materiel?.price ? `${materiel?.price}` : '0.00'
  }
}
