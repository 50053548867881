import { Component, Prop } from 'vue-property-decorator'
import MaterielLibreDialog from '@/components/shared/Dialogs/Materiels/MaterielLibreDialog.vue'
import CommonMateriel from './CommonMateriel'

@Component({
  components: {
    MaterielLibreDialog
  }
})
export default class MaterielLibre extends CommonMateriel {
}
