import { Component, Vue, Prop } from 'vue-property-decorator'
import MaterielTimeline from '@/views/Patients/Materiel/MaterielTimeline.vue'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { PediatryService } from '@/services/patient-pediatry-service'
import { PediatryMaterielModel } from '@/models/podiatry-observation-model'
import { ErrorService } from '@/services/error.service'
import { MaterielCategoryEnum } from '../Dossier/Constants'

@Component({
  components: {
    Alert,
    Confirm,
    MaterielTimeline
  }
})
export default class CommonMateriel extends Vue {
  private pediatryService = PediatryService.getInstance()

  @Prop()
  public category!: MaterielCategoryEnum

  @Prop()
  public type!: number

  @Prop({ default: undefined })
  public dossierId!: string

  public showDialog = false
  public materials: PediatryMaterielModel[] = []
  public isMaterialLoading = false
  public errorMessages: string[] = []
  public showConfirm = false

  private selectedItem: PediatryMaterielModel = {}

  public mounted () {
    this.refreshMaterial()
  }

  public addMateriel () {
    this.showDialog = true
  }

  public refreshMaterial () {
    this.isMaterialLoading = true
    this.pediatryService.getPatientMateriel(this.dossierId, this.type).then(items => {
      this.materials = items
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.isMaterialLoading = false
    })
  }

  public deleteMaterial (item: PediatryMaterielModel) {
    this.showConfirm = true
    this.selectedItem = item
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      this.isMaterialLoading = true
      this.pediatryService.deletePateintPediatryMateriel(this.selectedItem.id).then(() => {
        this.errorMessages = []
        this.refreshMaterial()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isMaterialLoading = false
      })
    }
  }

  public editMateriel (item: PediatryMaterielModel) {
    this.selectedItem = item
    this.showDialog = true
  }

  public closeDialog () {
    this.showDialog = false
    this.selectedItem = {}
    this.refreshMaterial()
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
