


































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class EmptyTimeLine extends Vue {
  public emitIconClick () {
    this.$emit('icon-click')
  }
}
