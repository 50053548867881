import { MaterielModel, PediatryMaterielModel } from '@/models/podiatry-observation-model'
import { ErrorService } from '@/services/error.service'
import { PediatryService } from '@/services/patient-pediatry-service'
import { MaterielCategoryEnum } from '@/views/Patients/Dossier/Constants'
import { ValidationObserver } from 'vee-validate'
import { Vue, Prop, Watch } from 'vue-property-decorator'
import Commons from '../../Helpers/commons'

export default abstract class MaterielDialogCommon extends Vue {
  protected pediatryService = PediatryService.getInstance()

  @Prop()
  public dossierId!: string

  @Prop()
  public showDialog!: boolean

  @Prop()
  public type!: number

  @Prop()
  public category!: MaterielCategoryEnum

  @Prop()
  protected selectedItem!: PediatryMaterielModel

  public errorMessages: string[] = []

  public selectedMateriel: MaterielModel = {}

  public isMaterialLoading = false

  public materials: MaterielModel[] = []

  protected formState: PediatryMaterielModel = {}

  public forceRefreshPrice = false

  public async save (close: boolean | any) {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isMaterialLoading = true
      const model: PediatryMaterielModel = this.buildModel()
      this.pediatryService.upsetMateriel(this.dossierId, model, model.materialId!).then(() => {
        if (close === false) {
          if (observer) observer.reset()
          this.$emit('resetMaterielSelection')
        } else {
          this.close()
        }
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      }).finally(() => {
        this.isMaterialLoading = false
      })
    } else {
      let merged = this.$refs
      if (this.$refs.commonFormRef) {
        const commonFormRefs = (this.$refs.commonFormRef as Vue).$refs
        merged = Object.assign(merged, commonFormRefs)
        if (commonFormRefs.DateRef) {
          const dateRefs = (commonFormRefs.DateRef as Vue).$refs
          merged = Object.assign(merged, dateRefs)
          for (const prop in dateRefs) {
            if (Object.prototype.hasOwnProperty.call(dateRefs, prop)) {
              if (prop.startsWith('DateField')) {
                merged = Object.assign(merged, (dateRefs[prop] as Vue).$refs)
              }
            }
          }
        }
      }
      merged.DateEndRef = merged.DateStartRef = merged['DateField-DateRef']
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  public abstract buildModel (): PediatryMaterielModel;

  @Watch('showDialog')
  public onShowDialog (v: boolean): void {
    // dummy method that needs to be here. If we have the watch in all inheriting dialogs, then the watch will trigger several times in some cases
  }

  public close () {
    const observer = this.$refs.dialogObserver as InstanceType<typeof ValidationObserver>
    if (observer) observer.reset()
    this.selectedMateriel = {}
    this.hideAlert()
    this.$emit('close')
  }

  public abstract get isPediatryOnly (): boolean;

  public hideAlert () {
    this.errorMessages = []
  }

  public refreshExistingMaterial (refreshExistingMaterial: boolean) {
    if (refreshExistingMaterial && this.formState.materiel?.id) {
      const refreshed = this.materials.find(x => x.id === this.formState.materiel?.id)
      if (refreshed) {
        this.formState.materiel = refreshed
        this.forceRefreshPrice = !this.forceRefreshPrice
      }
    }
  }
}
